import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { PageTransition } from '@steveeeie/react-page-transition';
import { useWindowWidth } from '@react-hook/window-size';
import Layout from './Layout/layout';
import AppRoutes from './routes';

const Desktop: FC = () => {
	const location = useLocation();
	return (
		<Layout>
			<PageTransition
				preset="slide"
				transitionKey={location.pathname}
				enterAnimation="moveToLeft"
				exitAnimation="moveToLeft"
			>
				<AppRoutes />
			</PageTransition>
		</Layout>
	);
};

const Mobile: FC = () => {
	return (
		<Layout>
			<AppRoutes />
		</Layout>
	);
};

function App() {
	const width = useWindowWidth();
	return <>{width < 480 ? <Mobile /> : <Desktop />}</>;
}

export default App;
