import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

type Blog = {
	title: string;
	slug: string;
	dateAdded: string;
	brief: string;
	coverImage: string;
};
interface Data {
	data: {
		user: {
			publication: {
				posts: Array<Blog>;
			};
		};
	};
}

const useFetchBlogPost = () => {
	const [data, setData] = useState<Data>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const ENDPOINT = process.env.REACT_APP_HASHNODE_HOSTNAME as string;
	const USERNAME = process.env.REACT_APP_HASHNODE_USERNAME as string;

	const fetchPosts = async (page: number, username: string) => {
		const requestBody = {
			query: `query  fetchPosts($page: Int, $username: String!) {
      user(username: $username) {
         publication {
          posts(page: $page) {
            title
            slug
            dateAdded
            brief
            coverImage
          }
        } 
      }
    }`,
			variables: {
				page,
				username,
			},
		};

		try {
			return await axios.post(ENDPOINT, requestBody);
		} catch (e) {
			return Promise.reject(e);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		fetchPosts(0, USERNAME)
			.then((response: AxiosResponse<Data>) => {
				setIsLoading(false);
				setData(response.data);
			})
			.catch((e) => {
				setIsLoading(false);
				return Promise.reject(e);
			});
	}, []);

	return { data, isLoading };
};
export default useFetchBlogPost;
