import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Navbar from './navbar';
import BioCard from './bioCard';

interface LayoutProps {
	children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
	const { pathname } = useLocation();
	return (
		<div className="font-poppins w-screen h-screen bg-[linear-gradient(135deg,_#85898A_0%,_#E5E6E8_100%)] flex justify-start items-start lg:justify-center lg:items-center">
			<Toaster position="top-center" reverseOrder={false} gutter={8} />
			<div className="relative lg:static flex flex-col lg:flex-row">
				<div className="fixed lg:static z-40">
					<Navbar />
				</div>
				<div
					className={`${
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						pathname === '/' ? 'block' : 'hidden lg:block'
					}  mt-20 lg:mt-0`}
				>
					<BioCard />
				</div>
				<div className="w-screen lg:w-[46.20117rem] min-h-[40.25rem] md:h-[40.25rem] my-[0.94rem]">
					{children}
				</div>
			</div>
		</div>
	);
};

export default Layout;
