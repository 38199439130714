import { FC } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import About from '../pages/About';
import Resume from '../pages/Resume';
import Works from '../pages/Works';
import Blog from '../pages/Blog';
// import SinglePostPage from '../pages/SinglePostPage.tsx.bk';
import Contact from '../pages/Contact';

const AppRoutes: FC = () => {
	const location = useLocation();
	return (
		<Routes location={location}>
			<Route path="/" element={<About />} />
			<Route path="/resume" element={<Resume />} />
			<Route path="/works" element={<Works />} />
			<Route path="/blog" element={<Blog />} />
			{/*<Route path="/blog/:slug" element={<SinglePostPage />} />*/}
			<Route path="/contact" element={<Contact />} />
		</Routes>
	);
};

export default AppRoutes;
