import { FC } from 'react';
import useFetchBlogPost from '../utils/hooks/useFetchPosts';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import { Link } from 'react-router-dom';

type Post = {
	title: string;
	slug: string;
	dateAdded: string;
	brief: string;
	coverImage: string;
};

const Blog: FC = () => {
	const HOSTNAME = process.env.REACT_APP_HASHNODE_BLOG_HOSTNAME as string;
	const { data, isLoading } = useFetchBlogPost();

	const posts: Array<Post> | undefined = data?.data.user.publication.posts;

	return (
		<main className="p-4 lg:p-[1.88rem] w-full mt-16 lg:mt-0 h-screen lg:h-full bg-[#FFFFFF] overflow-y-auto">
			<section className="pb-[1.88rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%) border-b-[0.03rem]">
				<div className="relative mb-[1.78rem] pb-[1.84rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%) border-b-[0.03rem]">
					<div className="absolute">
						<svg
							width="31"
							height="31"
							viewBox="0 0 31 31"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="0.0163879"
								y="0.5"
								width="30"
								height="30"
								rx="15"
								fill="url(#paint0_linear_1_234)"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_1_234"
									x1="0.0163879"
									y1="0.5"
									x2="30.0164"
									y2="30.5"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#4C7753" stopOpacity="0.4" />
									<stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<h1 className="ml-[0.75rem] text-[#4C7753] text-[1.25rem] font-bold">
						Blog
					</h1>
				</div>
				<div>
					{isLoading ? (
						<div className="flex justify-center items-center mt-44 w-full">
							<TailSpin
								height="80"
								width="80"
								color="#4C7753"
								ariaLabel="tail-spin-loading"
								radius="1"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
						</div>
					) : (
						<div className="flex flex-row justify-center items-center flex-wrap w-full">
							{posts?.map((blog: Post) => {
								return (
									<Link
										target="_blank"
										rel="noopener noreferrer"
										to={`https://${HOSTNAME}/${blog.slug}`}
										className="p-[1rem] border-[0.03rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%)] hover:shadow-2xl hover:-translate-y-2 duration-200 "
										key={blog.slug}
									>
										<div className="relative w-full md:w-[18.375rem] h-[15.375rem] flex justify-center items-center bg-[linear-gradient(134deg,_rgba(0,_0,_0,0.30)_0%,_rgba(0,_0,_0,0.30)_100%)]">
											<img
												src={blog.coverImage}
												alt={blog.title}
												className="z-10 w-full h-full object-contain"
											/>
										</div>
										<div className="w-full md:w-[18.375rem] flex flex-col justify-center items-center text-center space-y-[0.5rem] mt-[0.83rem]">
											<h2 className="px-[0.38rem] font-normal text-[#4C7753] text-[0.8125rem] border-[#4C7753] border-[0.063rem] rounded-[0.25rem] w-fit">
												{moment(blog.dateAdded)
													.format('DD MMM YYYY')
													.toUpperCase()}
											</h2>
											<h2 className="text-[#4C7753] text-[1rem] font-semibold">
												{blog.title}
											</h2>
											<p className="text-[#646464] text-[0.875rem] text-justify">
												{blog.brief.substring(0, 210)}{' '}
												<span className="font-bold">...Read more</span>
											</p>
										</div>
									</Link>
								);
							})}
						</div>
					)}
				</div>
			</section>
		</main>
	);
};

export default Blog;
