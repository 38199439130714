/* eslint-disable */
import React, { FC } from "react";
import Testimonial from "../components/testimonial";
import { BiSolidTennisBall } from "react-icons/bi";
import { GiPublicSpeaker, GiGamepad, GiDramaMasks } from "react-icons/gi";
import { CgFileDocument } from "react-icons/cg";
import { LiaProjectDiagramSolid } from "react-icons/lia";

const About: FC = () => {
  return (
    <div className="p-4 lg:p-[1.88rem] w-full h-full bg-[#FFFFFF] overflow-y-auto">
      <section>
        <div className="relative mb-[1.78rem] pb-[1.84rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%) border-b-[0.03rem]">
          <div className="absolute">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.0163879"
                y="0.5"
                width="30"
                height="30"
                rx="15"
                fill="url(#paint0_linear_1_234)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_234"
                  x1="0.0163879"
                  y1="0.5"
                  x2="30.0164"
                  y2="30.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4C7753" stopOpacity="0.4" />
                  <stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h1 className="ml-[0.75rem] text-[#4C7753] text-[1.25rem] font-bold">
            About <span>Me</span>
          </h1>
        </div>
        <p className="text-[#646464] text-[1rem] leading-[1.8rem]">
          <span className="font-bold text-[#4C7753]">
            Hello I'm Derrick Madumere
          </span>
          , a fullstack developer with 4+ years of experience, proficient in a
          wide range of technologies and tech stacks. Experienced in developing
          web applications using React, TypeScript, and Tailwind CSS for
          seamless user experiences. Adept at creating feature-rich platforms,
          such as Movie Planet, with a focus on innovation and usability.
          Skilled in backend development using Node.js, Express.js, and MongoDB,
          as demonstrated in projects like Chat App and EShopper. Excel in
          crafting visually appealing and responsive user interfaces while
          ensuring code quality and maintainability. Committed to enhancing
          digital experiences and driving success in the IT sector.
        </p>
      </section>
      <section className="mt-[2.5rem]">
        <div className="relative  pb-[1.84rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%) border-b-[0.03rem]">
          <div className="absolute">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.0163879"
                y="0.5"
                width="30"
                height="30"
                rx="15"
                fill="url(#paint0_linear_1_234)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_234"
                  x1="0.0163879"
                  y1="0.5"
                  x2="30.0164"
                  y2="30.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4C7753" stopOpacity="0.4" />
                  <stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h1 className="ml-[0.75rem] text-[#4C7753] text-[1.25rem] font-bold">
            My <span>Services</span>
          </h1>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col md:flex-row w-full">
            <div className="flex flex-col w-full lg:w-1/2 p-[1.88rem]">
              <div className="pb-[0.63rem]">
                <svg
                  width="60"
                  height="61"
                  viewBox="0 0 60 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.078125"
                    width="60"
                    height="60"
                    rx="30"
                    fill="url(#paint0_linear_1_241)"
                  />
                  <path
                    d="M36.7504 25.4062L37.0004 22.6719H23.1879L23.9223 31.0156H33.4848L33.141 34.5781L30.0629 35.4062L27.0004 34.5781L26.7973 32.3906H24.0629L24.4066 36.7344L30.0629 38.2969H30.1254V38.2812L35.7348 36.7344L36.516 28.2344H26.4535L26.2191 25.4062H36.7504ZM19.0941 18.0781H41.0941L39.0941 40.5469L30.0629 43.0781L21.0941 40.5469L19.0941 18.0781Z"
                    fill="#4C7753"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1_241"
                      x1="0"
                      y1="0.078125"
                      x2="60"
                      y2="60.0781"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#4C7753" stopOpacity="0.15" />
                      <stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h2 className="font-semibold text-[#323232] text-[1.0625rem] pb-[0.24rem] ">
                Front-end
              </h2>
              <p className="font-normal text-[#646464] text-[1rem] leading-[1.8rem]">
                Crafting modern, mobile-responsive websites to amplify your
                marketing reach..
              </p>
            </div>
            <div className="flex flex-col w-full lg:w-1/2 p-[1.88rem]">
              <div className="pb-[0.63rem]">
                <svg
                  width="61"
                  height="61"
                  viewBox="0 0 61 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.59375"
                    y="0.21875"
                    width="60"
                    height="60"
                    rx="30"
                    fill="url(#paint0_linear_3_946)"
                  />
                  <path
                    d="M22 19H38C38.2652 19 38.5196 19.1054 38.7071 19.2929C38.8946 19.4804 39 19.7348 39 20V24C39 24.2652 38.8946 24.5196 38.7071 24.7071C38.5196 24.8946 38.2652 25 38 25H22C21.7348 25 21.4804 24.8946 21.2929 24.7071C21.1054 24.5196 21 24.2652 21 24V20C21 19.7348 21.1054 19.4804 21.2929 19.2929C21.4804 19.1054 21.7348 19 22 19ZM22 27H38C38.2652 27 38.5196 27.1054 38.7071 27.2929C38.8946 27.4804 39 27.7348 39 28V28.67L36.5 27.56L30 30.44V33H22C21.7348 33 21.4804 32.8946 21.2929 32.7071C21.1054 32.5196 21 32.2652 21 32V28C21 27.7348 21.1054 27.4804 21.2929 27.2929C21.4804 27.1054 21.7348 27 22 27ZM22 35H30C30.06 37.25 31 39.4 32.46 41H22C21.7348 41 21.4804 40.8946 21.2929 40.7071C21.1054 40.5196 21 40.2652 21 40V36C21 35.7348 21.1054 35.4804 21.2929 35.2929C21.4804 35.1054 21.7348 35 22 35ZM27 23H28V21H27V23ZM27 31H28V29H27V31ZM27 39H28V37H27V39ZM23 21V23H25V21H23ZM23 29V31H25V29H23ZM23 37V39H25V37H23ZM36.5 30L41 32V35C41 37.78 39.08 40.37 36.5 41C33.92 40.37 32 37.78 32 35V32L36.5 30ZM36.5 31.94L34 33.06V35.72C34 37.26 35.07 38.7 36.5 39.06V31.94Z"
                    fill="#4C7753"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_3_946"
                      x1="0.59375"
                      y1="0.21875"
                      x2="60.5938"
                      y2="60.2188"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#4C7753" stop-opacity="0.15" />
                      <stop
                        offset="1"
                        stop-color="#4C7753"
                        stop-opacity="0.01"
                      />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h2 className="font-semibold text-[#323232] text-[1.0625rem] pb-[0.24rem] ">
                Back-end
              </h2>
              <p className="font-normal text-[#646464] text-[1rem] leading-[1.8rem]">
                Scalable backend solutions for seamless data management and
                enhanced user experiences..
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full">
            <div className="flex flex-col w-full lg:w-1/2 p-[1.88rem]">
              <div className="pb-[0.63rem]">
                <div className="w-[3.75rem] h-[3.75rem] bg-[linear-gradient(135deg,_rgba(76,_119,_83,_0.15)_0%,_rgba(76,_119,_83,_0.01)_100%)] rounded-full flex justify-center items-center">
                  <CgFileDocument
                    style={{
                      height: "1.75rem",
                      width: "1.75rem",
                      color: "#4C7753",
                    }}
                  />
                </div>
              </div>
              <h2 className="font-semibold text-[#323232] text-[1.0625rem] pb-[0.24rem] ">
                Technical Writing
              </h2>
              <p className="font-normal text-[#646464] text-[1rem] leading-[1.8rem]">
                Expertly crafted technical documentation to bridge the gap
                between complex concepts and user comprehension.
              </p>
            </div>
            <div className="flex flex-col w-full lg:w-1/2 p-[1.88rem]">
              <div className="pb-[0.63rem]">
                <div className="w-[3.75rem] h-[3.75rem] bg-[linear-gradient(135deg,_rgba(76,_119,_83,_0.15)_0%,_rgba(76,_119,_83,_0.01)_100%)] rounded-full flex justify-center items-center">
                  <LiaProjectDiagramSolid
                    style={{
                      height: "1.75rem",
                      width: "1.75rem",
                      color: "#4C7753",
                    }}
                  />
                </div>
              </div>
              <h2 className="font-semibold text-[#323232] text-[1.0625rem] pb-[0.24rem] ">
                Project Management
              </h2>
              <p className="font-normal text-[#646464] text-[1rem] leading-[1.8rem]">
                Efficient project management expertise to ensure seamless
                execution and successful outcomes.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Testimonial />
      </section>
      <section>
        <div className="relative  pb-[1.84rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%) border-b-[0.03rem]">
          <div className="absolute">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.0163879"
                y="0.5"
                width="30"
                height="30"
                rx="15"
                fill="url(#paint0_linear_1_234)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_234"
                  x1="0.0163879"
                  y1="0.5"
                  x2="30.0164"
                  y2="30.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4C7753" stopOpacity="0.4" />
                  <stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h1 className="ml-[0.75rem] text-[#4C7753] text-[1.25rem] font-bold">
            Fun <span>Fact</span>
          </h1>
        </div>
        <div className="flex flex-wrap flex-row justify-center items-center">
          <div className="w-1/2 md:w-1/4 px-[2.62rem] py-[2.5rem] flex flex-col justify-center items-center space-y-[1rem]">
            <BiSolidTennisBall
              style={{ color: "#4C7753", width: "1.75rem", height: "1.75rem" }}
            />
            <p className="text-[#646464] text-[1rem] text-center">
              Play Lawn tennis
            </p>
          </div>
          <div className="w-1/2 md:w-1/4 px-[2.62rem] py-[2.5] flex flex-col justify-center items-center space-y-[1rem]">
            <GiDramaMasks
              style={{ color: "#4C7753", width: "1.75rem", height: "1.75rem" }}
            />
            <p className="text-[#646464] text-[1rem] text-center">
              Enjoy the theatre
            </p>
          </div>
          <div className="w-1/2 md:w-1/4 px-[2.62rem] py-[2.5] flex flex-col justify-center items-center space-y-[1rem]">
            <GiGamepad
              style={{ color: "#4C7753", width: "1.75rem", height: "1.75rem" }}
            />
            <p className="text-[#646464] text-[1rem] text-center">
              Play video games
            </p>
          </div>
          <div className="w-1/2 md:w-1/4 px-[2.62rem] py-[2.5] flex flex-col justify-center items-center space-y-[1rem]">
            <GiPublicSpeaker
              style={{ color: "#4C7753", width: "1.75rem", height: "1.75rem" }}
            />
            <p className="text-[#646464] text-[1rem] text-center">
              Love public speaking
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
