/* eslint-disable */
import React, { FC } from "react";
import {
  SiJavascript,
  SiTypescript,
  SiExpress,
  SiHtml5,
  SiTailwindcss,
  SiRedux,
  SiJira,
  SiMongodb,
} from "react-icons/si";
import { FaNodeJs, FaCss3, FaReact, FaGitAlt } from "react-icons/fa";
import { TbBrandNextjs } from "react-icons/tb";
import Brian from "../images/brain.png";

const Resume: FC = () => {
  return (
    <main className="p-4 lg:p-[1.88rem] w-full mt-16 lg:mt-0 h-full bg-[#FFFFFF] overflow-y-auto ">
      <section className="pb-[1.88rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%)  border-b-[0.03rem]">
        <div className="relative mb-[1.78rem] pb-[1.84rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%) border-b-[0.03rem]">
          <div className="absolute">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.0163879"
                y="0.5"
                width="30"
                height="30"
                rx="15"
                fill="url(#paint0_linear_1_234)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_234"
                  x1="0.0163879"
                  y1="0.5"
                  x2="30.0164"
                  y2="30.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4C7753" stopOpacity="0.4" />
                  <stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h1 className="ml-[0.75rem] text-[#4C7753] text-[1.25rem] font-bold">
            Resume
          </h1>
        </div>
        <div>
          <div className="flex flex-row justify-start items-center space-x-[0.62rem] lg:pl-[0.79rem] pb-[1.09rem] pt-[0.2rem] ">
            <div>
              <svg
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 20.25V19H3.75V24C3.75 25.3875 4.8625 26.5 6.25 26.5H23.75C25.1375 26.5 26.25 25.3875 26.25 24V19H17.5V20.25H12.5ZM25 9H20V6.5L17.5 4H12.5L10 6.5V9H5C3.625 9 2.5 10.125 2.5 11.5V15.25C2.5 16.6375 3.6125 17.75 5 17.75H12.5V15.25H17.5V17.75H25C26.375 17.75 27.5 16.625 27.5 15.25V11.5C27.5 10.125 26.375 9 25 9ZM17.5 9H12.5V6.5H17.5V9Z"
                  fill="#4C7753"
                />
              </svg>
            </div>
            <h1 className="text-[#323232] text-[1.0625rem] font-bold">
              EXPERIENCE
            </h1>
          </div>
          <div className="flex flex-col justify-start w-full space-y-[1.9rem]">
            <div className="flex flex-col justify-start w-full space-y-[0.5rem] lg:pl-[0.79rem] lg:pr-[1.88] px-0 md:px-[1.88rem]">
              <h2 className="px-[0.38rem] font-normal text-[#4C7753] text-[0.8125rem] border-[#4C7753] border-[0.063rem] rounded-[0.25rem] w-fit">
                Jan 2023 - Present
              </h2>
              <div className="flex flex-col w-fit space-y-[0.08rem]">
                <h3 className="text-[#323232] text-[1.0625rem] font-normal">
                  Front-End Engineer
                </h3>
                <p className="text-[0.975rem] text-[rgba(100,100,100,0.60)]">
                  Tappi Inc.
                </p>
              </div>
              <ul className="list-disc	text-[#646464] text-[0.875rem] pl-[0.65rem]">
                <li>
                  Developed Merchant Landing Pages using Next.js, a powerful
                  React framework, to enhance online visibility and expand the
                  customer base.
                </li>
                <li>
                  Achieved a performance score increase from 40 to 90 by
                  implementing server-side props in Next.jsÐ
                </li>
                <li>
                  Implemented cost-saving features, including discounts and
                  special offers, recognized by key merchants for reducing
                  expenses related to traditional marketing methods
                </li>
                <li>
                  Introduced unit testing using Jest to boost productivity and
                  maintain code quality with tools like Eslint and prettier.
                </li>
                <li>
                  Planned future enhancements with TypeScript for rigorous type
                  checking to elevate platform robustness and reliability.
                </li>
                <li>
                  Received high merchant satisfaction due to the professional
                  appearance and functionality of landing pages, enhancing
                  online credibility.
                </li>
              </ul>
            </div>
            <div className="flex flex-col justify-start w-full space-y-[0.5rem] lg:pl-[0.79rem] lg:pr-[1.88] px-0 md:px-[1.88rem]">
              <h2 className="px-[0.38rem] font-normal text-[#4C7753] text-[0.8125rem] border-[#4C7753] border-[0.063rem] rounded-[0.25rem] w-fit">
                Jan 2022 - Jan 2023
              </h2>
              <div className="flex flex-col w-fit space-y-[0.08rem">
                <h3 className="text-[#323232] text-[1.0625rem] font-normal">
                  Front-End Engineer
                </h3>
                <p className="text-[0.975rem] text-[rgba(100,100,100,0.60)]">
                  Ajua
                </p>
              </div>
              <ul className="list-disc	text-[#646464] text-[0.875rem] pl-[0.65rem]">
                <li>
                  Translated intricate Figma UI designs into functional code
                  using Next.js/React.jsÐ
                </li>
                <li>
                  Analyzed and addressed functional and non-functional
                  requirements to deliver optimized solutions.
                </li>
                <li>
                  Developed and maintained internal and public APIs, ensuring
                  they were of world-class quality.
                </li>
                <li>
                  Conducted in-depth analysis of both functional and
                  non-functional requirements, designing solutions that
                  optimally addressed these requirements while considering
                  existing constraints.
                </li>
                <li>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Consistently maintained the platform's uptime, performance,
                  stability, and scalability, ensuring the highest level of
                  service for users.
                </li>
              </ul>
            </div>
            <div className="flex flex-col justify-start w-full space-y-[0.5rem] lg:pl-[0.79rem] lg:pr-[1.88] px-0 md:px-[1.88rem]">
              <h2 className="px-[0.38rem] font-normal text-[#4C7753] text-[0.8125rem] border-[#4C7753] border-[0.063rem] rounded-[0.25rem] w-fit">
                July 2019 - Dec 2022
              </h2>
              <div className="flex flex-col w-fit space-y-[0.08rem">
                <h3 className="text-[#323232] text-[1.0625rem] font-normal">
                  Front-End Developer
                </h3>
                <p className="text-[0.975rem] text-[rgba(100,100,100,0.60)]">
                  Contract/Freelance
                </p>
              </div>
              <ul className="list-disc	text-[#646464] text-[0.875rem] pl-[0.65rem]">
                <li>
                  Developed Movie Planet, a feature-rich movie application using
                  HTML/CSS, React, and TypeScript
                </li>
                <li>
                  Provided users with access to a vast catalog of movies and
                  detailed information about each film, including crew, cast,
                  release dates, similar movies, reviews, and recommendationsÐ
                </li>
                <li>
                  Implemented an infinite scroll feature for a seamless browsing
                  experience, automatically loading more movies as users scroll
                  through a category.
                </li>
                <li>
                  Maintained clear communication channels to provide project
                  updates and address client feedback.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-[1.9rem]">
          <div className="flex flex-row justify-start items-center space-x-[0.62rem] lg:pl-[0.79rem] pb-[1.09rem] pt-[0.2rem] ">
            <div>
              <svg
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.5 21.5C27.1458 21.5 26.8492 21.38 26.61 21.14C26.37 20.9008 26.25 20.6042 26.25 20.25V12.875L16.2188 18.3438C16.0313 18.4479 15.8383 18.5258 15.64 18.5775C15.4425 18.63 15.2292 18.6563 15 18.6563C14.7708 18.6563 14.5575 18.63 14.36 18.5775C14.1617 18.5258 13.9688 18.4479 13.7812 18.3438L3.25 12.5938C3.04167 12.4896 2.88042 12.3383 2.76625 12.14C2.65125 11.9425 2.59375 11.7292 2.59375 11.5C2.59375 11.2708 2.65125 11.0575 2.76625 10.86C2.88042 10.6617 3.04167 10.5104 3.25 10.4062L13.8125 4.65625C14 4.55208 14.1925 4.47375 14.39 4.42125C14.5883 4.36958 14.7917 4.34375 15 4.34375C15.2083 4.34375 15.4117 4.36958 15.61 4.42125C15.8075 4.47375 16 4.55208 16.1875 4.65625L28.0938 11.1563C28.3021 11.2604 28.4638 11.4112 28.5788 11.6087C28.6929 11.8071 28.75 12.0208 28.75 12.25V20.25C28.75 20.6042 28.63 20.9008 28.39 21.14C28.1508 21.38 27.8542 21.5 27.5 21.5ZM15 26.1562C14.7917 26.1562 14.5883 26.1304 14.39 26.0788C14.1925 26.0263 14 25.9479 13.8125 25.8438L7.5625 22.4688C7.14583 22.2396 6.82292 21.9325 6.59375 21.5475C6.36458 21.1617 6.25 20.7292 6.25 20.25V15.5L13.3438 19.3438C13.6146 19.4896 13.8854 19.6196 14.1562 19.7338C14.4271 19.8488 14.7083 19.9063 15 19.9063C15.2917 19.9063 15.5783 19.8437 15.86 19.7187C16.1408 19.5937 16.4167 19.4583 16.6875 19.3125L23.75 15.5V20.25C23.75 20.7292 23.6354 21.1617 23.4062 21.5475C23.1771 21.9325 22.8542 22.2396 22.4375 22.4688L16.1875 25.8438C16 25.9479 15.8075 26.0263 15.61 26.0788C15.4117 26.1304 15.2083 26.1562 15 26.1562Z"
                  fill="#4C7753"
                />
              </svg>
            </div>
            <h1 className="text-[#323232] text-[1.0625rem] font-bold">
              EDUCATION
            </h1>
          </div>
          <div className="flex flex-col justify-start w-full space-y-[1.9rem]">
            <div className="flex flex-col justify-start w-full space-y-[0.5rem] lg:pl-[0.79rem] lg:pr-[1.88] px-0 md:px-[1.88rem]">
              <h2 className="px-[0.38rem] font-normal text-[#4C7753] text-[0.8125rem] border-[#4C7753] border-[0.063rem] rounded-[0.25rem] w-fit">
                2013 - 2018
              </h2>
              <div className="flex flex-col w-fit space-y-[0.08rem]">
                <h3 className="text-[#323232] text-[1.0625rem] font-normal">
                  Federal University of Technology Owerri, Nigeria
                </h3>
                <p className="text-[0.975rem] text-[rgba(100,100,100,0.60)]">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Bachelor's degree, Mathematics
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-[1.88rem]">
        <div className="relative my-[1.78rem]">
          <div className="absolute">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.0163879"
                y="0.5"
                width="30"
                height="30"
                rx="15"
                fill="url(#paint0_linear_1_234)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_234"
                  x1="0.0163879"
                  y1="0.5"
                  x2="30.0164"
                  y2="30.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4C7753" stopOpacity="0.4" />
                  <stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h1 className="ml-[0.75rem] text-[#4C7753] text-[1.25rem] font-bold">
            My <span>Skills</span>
          </h1>
        </div>
        <div className="flex flex-col space-y-[1rem]">
          <div className="w-full">
            <div className="flex flex-row justify-start items-center space-x-[0.62rem] lg:pl-[0.79rem] pb-[1.09rem] mt-[0.5rem] ">
              <div>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 18.7812L2 12.7812L8 6.78125L9.425 8.20625L4.825 12.8062L9.4 17.3813L8 18.7812ZM16 18.7812L14.575 17.3563L19.175 12.7563L14.6 8.18125L16 6.78125L22 12.7812L16 18.7812Z"
                    fill="#4C7753"
                  />
                </svg>
              </div>
              <h1 className="text-[#323232] text-[1.0625rem] font-bold">
                TOOLS AND FRAMEWORK
              </h1>
            </div>
            <div className="flex flex-row flex-wrap justify-between  w-full">
              <div className="py-[0.75rem]  space-y-[1rem] px-4">
                <SiHtml5
                  style={{
                    height: "2.5rem",
                    width: "2.5rem",
                    color: "#4C7753",
                  }}
                />
                <p className="text-[#646464] text-[0.65rem] text-center">
                  HTML
                </p>
              </div>
              <div className="py-[0.75rem]  space-y-[1rem] px-4">
                <FaCss3
                  style={{
                    height: "2.5rem",
                    width: "2.5rem",
                    color: "#4C7753",
                  }}
                />
                <p className="text-[#646464] text-[0.65rem] text-center">CSS</p>
              </div>
              {/*<div className="py-[0.75rem]  space-y-[1rem] px-4">*/}
              {/*  <SiTailwindcss*/}
              {/*    style={{ height: "2.5rem", width: "2.5rem", color: "#4C7753" }}*/}
              {/*  />*/}
              {/*  <p className="text-[#646464] text-[0.65rem] text-center">*/}
              {/*    TailwindCSS*/}
              {/*  </p>*/}
              {/*</div>*/}
              <div className="py-[0.75rem]  space-y-[1rem] px-4">
                <SiJavascript
                  style={{
                    height: "2.5rem",
                    width: "2.5rem",
                    color: "#4C7753",
                  }}
                />
                <p className="text-[#646464] text-[0.65rem] text-center">
                  Javascript
                </p>
              </div>
              <div className="py-[0.75rem]  space-y-[1rem] px-4">
                <FaReact
                  style={{
                    height: "2.5rem",
                    width: "2.5rem",
                    color: "#4C7753",
                  }}
                />
                <p className="text-[#646464] text-[0.65rem] text-center">
                  ReactJs
                </p>
              </div>
              {/*<div className="py-[0.75rem]  space-y-[1rem] px-4">*/}
              {/*  <SiRedux*/}
              {/*    style={{ height: "2.5rem", width: "2.5rem", color: "#4C7753" }}*/}
              {/*  />*/}
              {/*  <p className="text-[#646464] text-[0.65rem] text-center">*/}
              {/*    ReactJs*/}
              {/*  </p>*/}
              {/*</div>*/}
              <div className="py-[0.75rem]  space-y-[1rem] px-4">
                <TbBrandNextjs
                  style={{
                    height: "2.5rem",
                    width: "2.5rem",
                    color: "#4C7753",
                  }}
                />
                <p className="text-[#646464] text-[0.65rem] text-center">
                  NextJs
                </p>
              </div>
              <div className="py-[0.75rem]  space-y-[1rem] px-4">
                <FaNodeJs
                  style={{
                    height: "2.5rem",
                    width: "2.5rem",
                    color: "#4C7753",
                  }}
                />
                <p className="text-[#646464] text-[0.65rem] text-center">
                  NodeJs
                </p>
              </div>
              <div className="py-[0.75rem]  space-y-[1rem] px-4">
                <SiExpress
                  style={{
                    height: "2.5rem",
                    width: "2.5rem",
                    color: "#4C7753",
                  }}
                />
                <p className="text-[#646464] text-[0.65rem] text-center">
                  ExpressJs
                </p>
              </div>
              <div className="py-[0.75rem] space-y-[1rem] px-4">
                <SiTypescript
                  style={{
                    height: "2.5rem",
                    width: "2.5rem",
                    color: "#4C7753",
                  }}
                />
                <p className="text-[#646464] text-[0.65rem] text-center">
                  Typescript
                </p>
              </div>
              {/*<div className="py-[0.75rem] space-y-[1rem] px-4">*/}
              {/*  <SiMongodb*/}
              {/*    style={{ height: "2.5rem", width: "2.5rem", color: "#4C7753" }}*/}
              {/*  />*/}
              {/*  <p className="text-[#646464] text-[0.65rem] text-center">*/}
              {/*    Mongodb*/}
              {/*  </p>*/}
              {/*</div>*/}
              {/*<div className="py-[0.75rem] space-y-[1rem] px-4">*/}
              {/*  <FaGitAlt*/}
              {/*    style={{ height: "2.5rem", width: "2.5rem", color: "#4C7753" }}*/}
              {/*  />*/}
              {/*  <p className="text-[#646464] text-[0.65rem] text-center">*/}
              {/*    Git*/}
              {/*  </p>*/}
              {/*</div>*/}
              {/*<div className="py-[0.75rem] space-y-[1rem] px-4">*/}
              {/*  <SiJira*/}
              {/*    style={{ height: "2.5rem", width: "2.5rem", color: "#4C7753" }}*/}
              {/*  />*/}
              {/*  <p className="text-[#646464] text-[0.65rem] text-center">*/}
              {/*    Jira*/}
              {/*  </p>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-row justify-start items-center space-x-[0.62rem] lg:pl-[0.79rem] pb-[1.09rem] mt-[0.5rem] ">
              <div>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_24_363)">
                    <path
                      d="M15 19.5312H20.25V21.0312H15V19.5312ZM16.5 22.5312H18.75V24.0312H16.5V22.5312ZM20.25 18.0312H18.75C18.75 17.2062 19.05 16.5313 19.65 15.9313L20.025 15.5563C20.625 14.9563 21 14.0563 21 13.1562C21 11.2812 19.5 9.78125 17.625 9.78125C15.75 9.78125 14.25 11.2812 14.25 13.1562C14.25 14.0563 14.625 14.8813 15.225 15.5563L15.6 15.9313C16.2 16.5313 16.5 17.2062 16.5 18.0312H15C15 17.6562 14.85 17.2813 14.55 16.9813L14.175 16.6063C13.275 15.7063 12.75 14.4313 12.75 13.1562C12.75 10.4563 14.925 8.28125 17.625 8.28125C20.325 8.28125 22.5 10.4563 22.5 13.1562C22.5 14.4313 21.975 15.7063 21.075 16.6063L20.7 16.9813C20.4 17.2813 20.25 17.6562 20.25 18.0312ZM4.5 17.2812H10.5V18.7812H4.5V17.2812Z"
                      fill="#4C7753"
                    />
                    <path
                      d="M9 15.7812C9.41421 15.7812 9.75 15.4455 9.75 15.0312C9.75 14.617 9.41421 14.2812 9 14.2812C8.58579 14.2812 8.25 14.617 8.25 15.0312C8.25 15.4455 8.58579 15.7812 9 15.7812Z"
                      fill="#4C7753"
                    />
                    <path
                      d="M4.5 14.2812H6.75V15.7812H4.5V14.2812ZM4.5 11.2812H9.75V12.7812H4.5V11.2812ZM7.5 8.28125H12.75V9.78125H7.5V8.28125Z"
                      fill="#4C7753"
                    />
                    <path
                      d="M5.25 9.78125C5.66421 9.78125 6 9.44546 6 9.03125C6 8.61704 5.66421 8.28125 5.25 8.28125C4.83579 8.28125 4.5 8.61704 4.5 9.03125C4.5 9.44546 4.83579 9.78125 5.25 9.78125Z"
                      fill="#4C7753"
                    />
                    <path
                      d="M12 5.28125H15V6.78125H12V5.28125Z"
                      fill="#4C7753"
                    />
                    <path
                      d="M9.75 6.78125C10.1642 6.78125 10.5 6.44546 10.5 6.03125C10.5 5.61704 10.1642 5.28125 9.75 5.28125C9.33579 5.28125 9 5.61704 9 6.03125C9 6.44546 9.33579 6.78125 9.75 6.78125Z"
                      fill="#4C7753"
                    />
                    <path
                      d="M4.5 5.28125H7.5V6.78125H4.5V5.28125Z"
                      fill="#4C7753"
                    />
                    <path
                      d="M13.5 21.7812H3V3.78125H16.5V6.78125H18V3.78125C18 2.95625 17.325 2.28125 16.5 2.28125H3C2.175 2.28125 1.5 2.95625 1.5 3.78125V21.7812C1.5 22.6063 2.175 23.2812 3 23.2812H13.5V21.7812Z"
                      fill="#4C7753"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_24_363">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.78125)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <h1 className="text-[#323232] text-[1.0625rem] font-bold">
                CORE COMPETENCES
              </h1>
            </div>
            <div className="flex flex-col md:flex-row justify-start md:justify-between items-start  pl-[0.79rem]">
              <div>
                <div className="flex flex-row space-x-[0.75rem]">
                  <div>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.5964 7.92141L10.4364 14.8314L8.53638 12.8014C8.18638 12.4714 7.63638 12.4514 7.23638 12.7314C6.84638 13.0214 6.73638 13.5314 6.97638 13.9414L9.22638 17.6014C9.44638 17.9414 9.82638 18.1514 10.2564 18.1514C10.6664 18.1514 11.0564 17.9414 11.2764 17.6014C11.6364 17.1314 18.5064 8.94141 18.5064 8.94141C19.4064 8.02141 18.3164 7.21141 17.5964 7.91141V7.92141Z"
                        fill="#4C7753"
                      />
                    </svg>
                  </div>
                  <p className="text-[#646464] text-[1rem]">
                    Cross-functional Collaboration
                  </p>
                </div>
                <div className="flex flex-row space-x-[0.75rem]">
                  <div>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.5964 7.92141L10.4364 14.8314L8.53638 12.8014C8.18638 12.4714 7.63638 12.4514 7.23638 12.7314C6.84638 13.0214 6.73638 13.5314 6.97638 13.9414L9.22638 17.6014C9.44638 17.9414 9.82638 18.1514 10.2564 18.1514C10.6664 18.1514 11.0564 17.9414 11.2764 17.6014C11.6364 17.1314 18.5064 8.94141 18.5064 8.94141C19.4064 8.02141 18.3164 7.21141 17.5964 7.91141V7.92141Z"
                        fill="#4C7753"
                      />
                    </svg>
                  </div>
                  <p className="text-[#646464] text-[1rem]">
                    API Development and Integration
                  </p>
                </div>
                <div className="flex flex-row space-x-[0.75rem]">
                  <div>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.5964 7.92141L10.4364 14.8314L8.53638 12.8014C8.18638 12.4714 7.63638 12.4514 7.23638 12.7314C6.84638 13.0214 6.73638 13.5314 6.97638 13.9414L9.22638 17.6014C9.44638 17.9414 9.82638 18.1514 10.2564 18.1514C10.6664 18.1514 11.0564 17.9414 11.2764 17.6014C11.6364 17.1314 18.5064 8.94141 18.5064 8.94141C19.4064 8.02141 18.3164 7.21141 17.5964 7.91141V7.92141Z"
                        fill="#4C7753"
                      />
                    </svg>
                  </div>
                  <p className="text-[#646464] text-[1rem]">Testing</p>
                </div>
              </div>
              <div>
                <div className="flex flex-row space-x-[0.75rem]">
                  <div>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.5964 7.92141L10.4364 14.8314L8.53638 12.8014C8.18638 12.4714 7.63638 12.4514 7.23638 12.7314C6.84638 13.0214 6.73638 13.5314 6.97638 13.9414L9.22638 17.6014C9.44638 17.9414 9.82638 18.1514 10.2564 18.1514C10.6664 18.1514 11.0564 17.9414 11.2764 17.6014C11.6364 17.1314 18.5064 8.94141 18.5064 8.94141C19.4064 8.02141 18.3164 7.21141 17.5964 7.91141V7.92141Z"
                        fill="#4C7753"
                      />
                    </svg>
                  </div>
                  <p className="text-[#646464] text-[1rem]">
                    Frontend/Backend Development
                  </p>
                </div>
                <div className="flex flex-row space-x-[0.75rem]">
                  <div>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.5964 7.92141L10.4364 14.8314L8.53638 12.8014C8.18638 12.4714 7.63638 12.4514 7.23638 12.7314C6.84638 13.0214 6.73638 13.5314 6.97638 13.9414L9.22638 17.6014C9.44638 17.9414 9.82638 18.1514 10.2564 18.1514C10.6664 18.1514 11.0564 17.9414 11.2764 17.6014C11.6364 17.1314 18.5064 8.94141 18.5064 8.94141C19.4064 8.02141 18.3164 7.21141 17.5964 7.91141V7.92141Z"
                        fill="#4C7753"
                      />
                    </svg>
                  </div>
                  <p className="text-[#646464] text-[1rem]">State Management</p>
                </div>
                <div className="flex flex-row space-x-[0.75rem]">
                  <div>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.5964 7.92141L10.4364 14.8314L8.53638 12.8014C8.18638 12.4714 7.63638 12.4514 7.23638 12.7314C6.84638 13.0214 6.73638 13.5314 6.97638 13.9414L9.22638 17.6014C9.44638 17.9414 9.82638 18.1514 10.2564 18.1514C10.6664 18.1514 11.0564 17.9414 11.2764 17.6014C11.6364 17.1314 18.5064 8.94141 18.5064 8.94141C19.4064 8.02141 18.3164 7.21141 17.5964 7.91141V7.92141Z"
                        fill="#4C7753"
                      />
                    </svg>
                  </div>
                  <p className="text-[#646464] text-[1rem]">
                    Debugging and Troubleshooting
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-row  justify-start items-center space-x-[0.62rem] lg:pl-[0.79rem] pb-[1.09rem] mt-[0.5rem] ">
              <div>
                <svg
                  width="21"
                  height="25"
                  viewBox="0 0 21 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_24_889)">
                    <path
                      d="M3 1.78125C3 0.951562 2.32969 0.28125 1.5 0.28125C0.670312 0.28125 0 0.951562 0 1.78125V22.7812C0 23.6109 0.670312 24.2812 1.5 24.2812C2.32969 24.2812 3 23.6109 3 22.7812V16.7812L6.01406 16.0266C7.94063 15.5437 9.97969 15.7687 11.7563 16.6547C13.8281 17.6906 16.2328 17.8172 18.3984 17.0016L20.025 16.3922C20.6109 16.1719 21 15.6141 21 14.9859V3.37969C21 2.30156 19.8656 1.59844 18.9 2.08125L18.45 2.30625C16.2797 3.39375 13.725 3.39375 11.5547 2.30625C9.90938 1.48125 8.02031 1.275 6.23438 1.72031L3 2.53125V1.78125Z"
                      fill="#4C7753"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_24_889">
                      <rect
                        width="21"
                        height="24"
                        fill="white"
                        transform="translate(0 0.28125)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <h1 className="text-[#323232] text-[1.0625rem] font-bold">
                LANGUAGES
              </h1>
            </div>
            <div className="flex flex-row justify-start items-start py-[0.75rem] pl-[0.79rem] space-x-12">
              <div className="flex flex-row justify-center items-center space-x-[0.75rem]">
                <div>
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6.29688" cy="6.03125" r="6" fill="#4C7753" />
                  </svg>
                </div>
                <p className="text-[#646464] text-[1rem]">English</p>
              </div>
              <div className="flex flex-row justify-center items-center space-x-[0.75rem]">
                <div>
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6.29688" cy="6.03125" r="6" fill="#4C7753" />
                  </svg>
                </div>
                <p className="text-[#646464] text-[1rem]">French</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-[1.88rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%) border-t-[0.03rem]">
        <div className="relative mb-[1.78rem]">
          <div className="absolute">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.0163879"
                y="0.5"
                width="30"
                height="30"
                rx="15"
                fill="url(#paint0_linear_1_234)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_234"
                  x1="0.0163879"
                  y1="0.5"
                  x2="30.0164"
                  y2="30.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4C7753" stopOpacity="0.4" />
                  <stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h1 className="ml-[0.75rem] text-[#4C7753] text-[1.25rem] font-bold">
            Quote
          </h1>
        </div>
        <div className="mt-[1.9rem] flex flex-row justify-around">
          <div>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.85554 4.07957C4.71779 6.09467 2.97464 8.44008 2.62607 11.1158C2.08342 15.2812 5.8085 17.3201 7.69596 15.4882C9.58342 13.6563 8.452 11.3311 7.08529 10.6955C5.71858 10.0599 4.88279 10.2812 5.02858 9.43187C5.17438 8.58254 7.11879 6.22762 8.82704 5.13091C8.94038 5.03454 8.9835 4.84754 8.87438 4.70566C8.80258 4.61237 8.66179 4.42939 8.452 4.15671C8.26854 3.91827 8.09279 3.92719 7.85554 4.07957Z"
                fill="#646464"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1162 4.07957C12.9785 6.09467 11.2353 8.44008 10.8867 11.1158C10.3441 15.2812 14.0692 17.3201 15.9567 15.4882C17.8441 13.6563 16.7127 11.3311 15.346 10.6955C13.9793 10.0599 13.1435 10.2812 13.2893 9.43187C13.4351 8.58254 15.3795 6.22762 17.0877 5.13091C17.2011 5.03454 17.2442 4.84754 17.135 4.70566C17.0633 4.61237 16.9225 4.42939 16.7127 4.15671C16.5292 3.91827 16.3535 3.92719 16.1162 4.07957Z"
                fill="#646464"
              />
            </svg>
          </div>
          <div className="flex flex-col justify-center items-center space-y-[2rem]">
            <i className="text-[#646464] text-[1rem] text-center">
              Life is in different stages. Every stage of life is the foundation
              for the next stage of life. Every stage of life must be
              fully-lived.
            </i>
            <div className="flex flex-col justify-start items-center space-y-[0.5rem]">
              <img
                src={Brian}
                alt="Brain Tracy"
                className="h-[3.75rem] w-[3.75rem] rounded-full"
              />
              <p className="text-[#646464] text-[1rem] text-center font-bold">
                Brain Tracey
              </p>
            </div>
          </div>
          <div>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1445 16.4829C15.2822 14.4678 17.0254 12.1224 17.3739 9.44671C17.9166 5.28125 14.1915 3.24238 12.304 5.07429C10.4166 6.90621 11.548 9.23142 12.9147 9.867C14.2814 10.5026 15.1172 10.2813 14.9714 11.1306C14.8256 11.98 12.8812 14.3349 11.173 15.4316C11.0596 15.528 11.0165 15.715 11.1256 15.8568C11.1974 15.9501 11.3382 16.1331 11.548 16.4058C11.7315 16.6442 11.9072 16.6353 12.1445 16.4829Z"
                fill="#646464"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.88374 16.4829C7.02144 14.4678 8.76461 12.1224 9.11319 9.44671C9.65582 5.28125 5.93074 3.24238 4.04328 5.07429C2.15582 6.90621 3.28724 9.23142 4.65395 9.867C6.02065 10.5026 6.85649 10.2813 6.71065 11.1306C6.56486 11.98 4.62044 14.3349 2.91219 15.4316C2.79886 15.528 2.75574 15.715 2.8649 15.8568C2.93665 15.9501 3.07744 16.1331 3.28724 16.4058C3.47069 16.6442 3.64644 16.6353 3.88374 16.4829Z"
                fill="#646464"
              />
            </svg>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Resume;
