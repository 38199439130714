/* eslint-disable */
import React, { FC } from "react";
import ReactSwipe from "react-swipe";
import { GrNext, GrPrevious } from "react-icons/gr";
import Chris from "../images/Chris.png";
import Laolu from "../images/Laolu.png";
import Jennifer from "../images/Jennifer.png";

const TESTIMONIALS = [
  {
    id: 1,
    name: "Jennifer Dodo-Okere",
    image: Jennifer,
    comment:
      "Derrick is a fantastic team player. He is very intelligent, resourceful, and very reliable. I am so proud of you and I wish you the very best in all your endeavors. Keep shining.",
  },
  {
    id: 2,
    name: "Olaoluwa Olanipekun",
    image: Laolu,
    comment:
      "Derrick is a fast learner and very passionate about what he does, he grew within a short period of time and you can always be assured he will get the job done in the most efficient way possible. keep up the pace Derrick, there is no limit to what you can archieve. ",
  },
  {
    id: 3,
    name: "Onwufuju Christopher",
    image: Chris,
    comment:
      "Derrick is an exceptional young software engineer. He is diligent to duty, takes his job like he is the CEO and wants the best out of it. he is also a great team player and support personnel. It was great having him in my team. All the best D-man.",
  },
];

interface testimonail {
  id: number;
  name: string;
  image: string;
  comment: string;
}

const Testimonial: FC = () => {
  let reactSwipeEl: any;
  return (
    <div>
      <div className="relative  pb-[1.84rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%) border-b-[0.03rem]">
        <div className="absolute">
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.0163879"
              y="0.5"
              width="30"
              height="30"
              rx="15"
              fill="url(#paint0_linear_1_234)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_234"
                x1="0.0163879"
                y1="0.5"
                x2="30.0164"
                y2="30.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4C7753" stopOpacity="0.4" />
                <stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <h1 className="ml-[0.75rem] text-[#4C7753] text-[1.25rem] font-bold">
          Testimonials
        </h1>
      </div>
      <div className="flex flex-row justify-between items-center w-full">
        <button
          className="py-[0.75rem] space-y-[1rem] px-4"
          onClick={() => reactSwipeEl.prev()}
        >
          <GrPrevious
            style={{ height: "1rem", width: "1rem", color: "#4C7753" }}
          />
        </button>
        <ReactSwipe
          className="carousel"
          swipeOptions={{ continuous: true, auto: 8000, speed: 1000 }}
          ref={(el) => (reactSwipeEl = el)}
        >
          {TESTIMONIALS.map((item: testimonail) => (
            <div className="flex flex-row space-x-[1.4rem] px-2.5  py-[2.5rem]">
              <div className="flex flex-col justify-start items-center space-y-[2rem] w-[34.0625rem]">
                <i className="text-[#646464] text-[1rem] text-center flex flex-row">
                  <span>
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.85554 3.90769C4.71779 5.92279 2.97464 8.26821 2.62607 10.9439C2.08342 15.1094 5.8085 17.1482 7.69596 15.3163C9.58342 13.4844 8.452 11.1592 7.08529 10.5236C5.71858 9.888 4.88279 10.1094 5.02858 9.26C5.17438 8.41066 7.11879 6.05575 8.82704 4.95904C8.94038 4.86266 8.9835 4.67566 8.87438 4.53379C8.80258 4.4405 8.66179 4.25752 8.452 3.98484C8.26854 3.74639 8.09279 3.75532 7.85554 3.90769Z"
                        fill="#646464"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.1162 3.90769C12.9785 5.92279 11.2353 8.26821 10.8867 10.9439C10.3441 15.1094 14.0692 17.1482 15.9567 15.3163C17.8441 13.4844 16.7127 11.1592 15.346 10.5236C13.9793 9.888 13.1435 10.1094 13.2893 9.26C13.4351 8.41066 15.3795 6.05575 17.0877 4.95904C17.2011 4.86266 17.2442 4.67566 17.135 4.53379C17.0633 4.4405 16.9225 4.25752 16.7127 3.98484C16.5292 3.74639 16.3535 3.75532 16.1162 3.90769Z"
                        fill="#646464"
                      />
                    </svg>
                  </span>
                  {item.comment}
                  <span>
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.1445 16.3111C15.2822 14.296 17.0254 11.9505 17.3739 9.27483C17.9166 5.10938 14.1915 3.0705 12.304 4.90242C10.4166 6.73433 11.548 9.05954 12.9147 9.69513C14.2814 10.3308 15.1172 10.1094 14.9714 10.9588C14.8256 11.8081 12.8812 14.163 11.173 15.2597C11.0596 15.3561 11.0165 15.5431 11.1256 15.685C11.1974 15.7783 11.3382 15.9612 11.548 16.2339C11.7315 16.4724 11.9072 16.4634 12.1445 16.3111Z"
                        fill="#646464"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.8838 16.3111C7.02151 14.296 8.76467 11.9505 9.11326 9.27483C9.65588 5.10938 5.9308 3.0705 4.04334 4.90242C2.15588 6.73433 3.2873 9.05954 4.65401 9.69513C6.02071 10.3308 6.85655 10.1094 6.71071 10.9588C6.56492 11.8081 4.62051 14.163 2.91226 15.2597C2.79892 15.3561 2.7558 15.5431 2.86496 15.685C2.93671 15.7783 3.07751 15.9612 3.2873 16.2339C3.47076 16.4724 3.6465 16.4634 3.8838 16.3111Z"
                        fill="#646464"
                      />
                    </svg>
                  </span>
                </i>
                <div className="flex flex-col justify-start items-center space-y-[0.5rem]">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="h-[3.75rem] w-[3.75rem] rounded-full"
                  />
                  <p className="text-[#646464] text-[1rem] text-center font-bold">
                    {item.name}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </ReactSwipe>
        <button
          className="py-[0.75rem] space-y-[1rem] px-4"
          onClick={() => reactSwipeEl.next()}
        >
          <GrNext style={{ height: "1rem", width: "1rem", color: "#4C7753" }} />
        </button>
      </div>
    </div>
  );
};

export default Testimonial;
