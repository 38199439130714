/* eslint-disable no-useless-escape */
import { FC, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { TailSpin } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

interface FormData {
	name: string;
	email: string;
	message: string;
}

const Contact: FC = () => {
	const SERVICE_ID = process.env.REACT_APP_SERVICE_ID as string;
	const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID as string;
	const USER_ID = process.env.REACT_APP_USER_ID as string;
	const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY as string;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isVerified, setIsVerified] = useState<boolean>(false);
	const navigate = useNavigate();

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<FormData>();
	const onSubmit: SubmitHandler<FormData> = (data) => {
		const serviceId = SERVICE_ID;
		const templateId = TEMPLATE_ID;
		const userId = USER_ID;
		const templateParams = {
			name: data.name,
			email: data.email,
			message: data.message,
		};

		if (isVerified) {
			setIsLoading(true);
			void emailjs
				.send(serviceId, templateId, templateParams, userId)
				.then((response) => {
					setIsLoading(false);
					toast.success('Submission successful');
					void Promise.resolve(response);
					navigate('/');
				})
				.catch((error) => {
					setIsLoading(false);
					toast.error('Oops!! Something went wrong. Please try again later');
					void Promise.reject(error);
				});
		} else {
			toast.error('Please ensure you check the reCaptcha');
		}
	};

	return (
		<main className="p-4 lg:p-[1.88rem] w-full mt-16 lg:mt-0 w-full h-screen md:h-full bg-[#FFFFFF] overflow-y-auto">
			<section>
				<div className="relative mb-[1.78rem] pb-[1.84rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%) border-b-[0.03rem]">
					<div className="absolute">
						<svg
							width="31"
							height="31"
							viewBox="0 0 31 31"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="0.0163879"
								y="0.5"
								width="30"
								height="30"
								rx="15"
								fill="url(#paint0_linear_1_234)"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_1_234"
									x1="0.0163879"
									y1="0.5"
									x2="30.0164"
									y2="30.5"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#4C7753" stopOpacity="0.4" />
									<stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<h1 className="ml-[0.75rem] text-[#4C7753] text-[1.25rem] font-bold">
						Get in touch
					</h1>
				</div>
			</section>
			<section className="w-full">
				<div className="flex flex-col justify-start space-y-[2rem] lg:space-y-0 w-full">
					<h2 className="text-[#667085] text-[1rem]">
						{/* eslint-disable-next-line react/no-unescaped-entities */}
						Let's level up your brand together
					</h2>
					<form
						className="flex flex-col justify-start space-y-[1.5rem] w-full"
						onSubmit={(event) => void handleSubmit(onSubmit)(event)}
					>
						<div className="flex flex-col md:flex-row space-y-[2rem] lg:space-y-0 lg:space-x-[2rem] lg:mt-5">
							<div className="flex flex-col">
								<p className="text-[0.875rem] text-[#344054]">Full name</p>
								<input
									{...register('name', { required: true })}
									aria-invalid={errors.name ? 'true' : 'false'}
									placeholder="Full name"
									type="text"
									className="py-[0.75rem] px-[1rem] rounded-[0.5rem] border-[0.05rem] border-[#D0D5DD] w-full lg:w-[19rem] mt-[0.38rem] focus:ring-[0.08rem] focus:ring-[#4C7753] focus:outline-none"
								/>
								{errors.name?.type === 'required' && (
									<p role="alert">{errors.name?.message}</p>
								)}
							</div>
							<div className="flex flex-col">
								<p className="text-[0.875rem] text-[#344054]">Email address</p>
								<input
									{...register('email', {
										required: true,
										pattern:
											/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									})}
									aria-invalid={errors.email ? 'true' : 'false'}
									placeholder="you@mail.com"
									type="email"
									className="py-[0.75rem] px-[1rem] rounded-[0.5rem] border-[0.05rem] border-[#D0D5DD] w-full lg:w-[19rem] mt-[0.38rem] focus:ring-[0.08rem] focus:ring-[#4C7753] focus:outline-none"
								/>
								{errors.name?.type === 'required' && (
									<p role="alert">{errors.email?.message}</p>
								)}
							</div>
						</div>
						<div className="flex flex-col">
							<p className="text-[0.875rem] text-[#344054]">
								How can I help you?
							</p>
							<textarea
								{...register('message', { required: true })}
								aria-invalid={errors.message ? 'true' : 'false'}
								placeholder="Tell me a little about the project..."
								className="py-[0.75rem] px-[1rem] rounded-[0.5rem] border-[0.05rem] border-[#D0D5DD] w-full mt-[0.38rem] resize-none focus:ring-[0.08rem] focus:ring-[#4C7753] focus:outline-none"
								rows={5}
							/>
							{errors.name?.type === 'required' && (
								<p role="alert">{errors.message?.message}</p>
							)}
						</div>
						<div>
							<ReCAPTCHA
								sitekey={`${RECAPTCHA_KEY}`}
								onChange={() => setIsVerified(true)}
								onExpired={() => setIsVerified(false)}
								onErrored={() => setIsVerified(false)}
							/>
						</div>
						{isLoading ? (
							<div className="flex justify-center items-center w-16">
								<TailSpin
									height="19"
									width="19"
									color="#4C7753"
									ariaLabel="tail-spin-loading"
									radius="1"
									wrapperStyle={{}}
									wrapperClass=""
									visible={true}
								/>
							</div>
						) : (
							<button
								className="flex flex-row space-x-[0.62rem] mt-[2rem]"
								type="submit"
							>
								<p className="text-[#323232] text-[0.875rem]">SEND MESSAGE</p>
								<svg
									width="19"
									height="19"
									viewBox="0 0 19 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.1748 14L10.1248 12.9125L12.7873 10.25H3.6748V8.75H12.7873L10.1248 6.0875L11.1748 5L15.6748 9.5L11.1748 14Z"
										fill="#323232"
									/>
								</svg>
							</button>
						)}
					</form>
				</div>
			</section>
		</main>
	);
};

export default Contact;
