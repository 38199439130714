/* eslint-disable */
import { FC } from "react";
import { Link } from "react-router-dom";
import eshopper from "../images/eshopper.jpeg";
import movies from "../images/moviePlanet.jpeg";
import chat from "../images/livechat.png";
import weather from "../images/weather.png";
import tappi from "../images/tappi.png";
import merchant from "../images/merchant.png";

const WORKS = [
  {
    name: "Merchant Website",
    description: "Making Tappi merchants visible online",
    image: merchant,
    url: "https://mavericks-restaurant.tappi.ke",
    github: null,
  },
  {
    name: "tappi",
    description: "Tappi Inc landing page",
    image: tappi,
    url: "https://tappi.app",
    github: null,
  },
  {
    name: "Movie Planet",
    description: "Search for any movie",
    image: movies,
    url: "https://mplanet.netlify.app",
    github: "https://github.com/Kachielite/movie-app",
  },
  {
    name: "eshopper",
    description: "An ecommerce dashboard web app",
    image: eshopper,
    url: "https://eshopper.com.ng",
    github: "https://github.com/Kachielite/eshopper",
  },
  {
    name: "Let's Chat",
    description: "A live chat application",
    image: chat,
    url: "https://quick-chat-app22.netlify.app/",
    github: "https://github.com/Kachielite/Chat-App",
  },
  {
    name: "Weather Forecast",
    description: "Get a 7 day weather forecast",
    image: weather,
    url: "https://weather-app28.netlify.app/",
    github: "https://github.com/Kachielite/weather-forecast",
  },
];

const Works: FC = () => {
  return (
    <main className="p-4 lg:p-[1.88rem] w-full mt-16 lg:mt-0 h-full bg-[#FFFFFF] overflow-y-auto ">
      <section className="pb-[1.88rem]">
        <div className="relative  pb-[1rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%)] border-b-[0.03rem]">
          <div className="absolute">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.0163879"
                y="0.5"
                width="30"
                height="30"
                rx="15"
                fill="url(#paint0_linear_1_234)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_234"
                  x1="0.0163879"
                  y1="0.5"
                  x2="30.0164"
                  y2="30.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4C7753" stopOpacity="0.4" />
                  <stop offset="1" stopColor="#4C7753" stopOpacity="0.01" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h1 className="ml-[0.75rem] text-[#4C7753] text-[1.25rem] font-bold">
            Recent <span>Works</span>
          </h1>
        </div>
      </section>
      <section className="w-full">
        <div className="flex flex-row flex-wrap justify-center items-center w-full">
          {WORKS.map((work) => {
            return (
              <div className="group w-full md:w-fit p-[1.88rem] md:p-[1rem] border-[0.03rem] border-[radial-gradient(70.71%_70.71%_at_50%_50%,_#DDD_0%,_rgba(255,_255,_255,_0.00)_70.00%)] hover:shadow-2xl hover:-translate-y-2 duration-200 ">
                <div className="relative w-full md:w-[18.375rem] h-[15.375rem] flex justify-center items-center ">
                  <img
                    src={work.image}
                    alt={work.name}
                    className="z-10 w-full h-full object-contain"
                  />
                  <div className=" z-20 absolute w-full h-full  flex flex-col justify-end">
                    <div className="hidden group-hover:flex duration-300 flex-row h-[3rem] justify-between rounded-t-[0.9375rem] px-[1.25rem] pt-[0.88rem] pb-[0.81rem] w-full  bg-[linear-gradient(134deg,_rgba(0,_0,_0,0.50)_0%,_rgba(0,_0,_0,0.50)_100%)]">
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={work.url}
                        className="flex flex-row items-center space-x-[0.25rem] text-white text-[0.875rem]"
                      >
                        Visit Site{" "}
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.6668 6.09992L4.7501 12.0333C4.61676 12.1666 4.45832 12.2333 4.27476 12.2333C4.09165 12.2333 3.93343 12.1666 3.8001 12.0333C3.66676 11.8999 3.6001 11.7415 3.6001 11.5579C3.6001 11.3748 3.66676 11.2166 3.8001 11.0833L9.73343 5.16659H4.66676C4.47788 5.16659 4.31943 5.10281 4.19143 4.97525C4.06388 4.84725 4.0001 4.68881 4.0001 4.49992C4.0001 4.31103 4.06388 4.15259 4.19143 4.02459C4.31943 3.89703 4.47788 3.83325 4.66676 3.83325H11.3334C11.5223 3.83325 11.6805 3.89703 11.8081 4.02459C11.9361 4.15259 12.0001 4.31103 12.0001 4.49992V11.1666C12.0001 11.3555 11.9361 11.5137 11.8081 11.6413C11.6805 11.7693 11.5223 11.8333 11.3334 11.8333C11.1445 11.8333 10.9863 11.7693 10.8588 11.6413C10.7308 11.5137 10.6668 11.3555 10.6668 11.1666V6.09992Z"
                            fill="white"
                          />
                        </svg>
                      </Link>
                      {work.github && (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={work.github}
                          className="flex flex-row items-center  space-x-[0.25rem] text-white text-[0.875rem]"
                        >
                          View Code{" "}
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.99984 13.8334L7.04984 12.9001L10.7832 9.16675H2.6665V7.83342H10.7832L7.04984 4.10008L7.99984 3.16675L13.3332 8.50008L7.99984 13.8334Z"
                              fill="white"
                            />
                          </svg>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-[18.375rem] flex flex-col justify-center items-center text-center space-y-[0.5rem] mt-[0.83rem]">
                  <h2 className="text-[#323232] text-[1rem] font-semibold">
                    {work.name}
                  </h2>
                  <p className="text-[#646464] text-[0.875rem] px-6">
                    {work.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </main>
  );
};

export default Works;
